import React from 'react';
import styled from 'styled-components';
import {S3Client} from "./clients/S3Client";

const ImageGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin : 0;
  padding:0;
  @media (max-width: 768px) {
    gap: 5px; /* Reduce gap for mobile view */
  }
`;

const Column = styled.div`
  flex: 1;

  @media (min-width: 768px) {
    flex: calc(50% - 5px); /* Two images in one row for tablet view */
  }

  @media (min-width: 1024px) {
    flex: calc(33.33% - 10px); /* Three images in one row for desktop view */
  }

  @media (max-width: 768px) {
    flex: 100%; /* One image in one row for mobile view */
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin-bottom:0;
  display:flex;
`;

const Image = styled.img`

  width: 100%;
  height: auto;
  overflow-clip-margin: 0;
`;

const Text = styled.div`
  position: absolute;
  bottom: 50px; /* Adjust the position of text */
  left: 10px; /* Adjust the position of text */
  background-color: white;
  padding: 5px;
  border-radius: 3px;
`;

const ImageGrid = () => {
  const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');

  // Dummy image sources
  const images = [
    'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image1.jpeg',
    'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image2.jpeg',
    'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image3.jpeg',
    'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image4.jpeg',
    'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image5.jpeg',
    'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image6.jpeg'
  ];

  const category = ["Simmer", "Dola", "Orgenja", "Lehriya", "Bandej", "Fancy Shifon", "Modra", "Fancy Shifon"]
  

  return (
    <ImageGridContainer>
      {images.map((image, index) => (
        <Column key={index}>
          <ImageContainer>
            <Image src={uploader.generateSignedUrl(image)} alt={`Image ${index + 1}`} />
            <Text>{category[index]}</Text>
          </ImageContainer>
        </Column>
      ))}
    </ImageGridContainer>
  );
};

export default ImageGrid;
