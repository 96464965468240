const SECRET = "9AvyW2EWBclwvGMcJmvim4zfBXLD6ngf";
export const encrypt = (data, secretKey) => {
    let encryptedData = '';
    for (let i = 0; i < data.length; i++) {
        let charCode = data.charCodeAt(i);
        let key = secretKey.charCodeAt(i % secretKey.length);
        let encryptedCharCode = charCode + key;
        encryptedData += String.fromCharCode(encryptedCharCode);
    }
    // const [decryptedUsername, decryptedPhone] = decrypt(encryptedData, SECRET);
    // console.log(decryptedPhone +  " " + decryptedUsername)
    return btoa(encryptedData); // Base64 encode the encrypted data for better representation
};


export const decrypt = (encryptedData) => {
    encryptedData = atob(encryptedData)
    let decryptedData = '';
    for (let i = 0; i < encryptedData.length; i++) {
        let charCode = encryptedData.charCodeAt(i);
        let key = SECRET.charCodeAt(i % SECRET.length);
        let decryptedCharCode = charCode - key;
        decryptedData += String.fromCharCode(decryptedCharCode);
    }
    console.log(decryptedData);
    return decryptedData;
};
