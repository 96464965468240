import React, { useEffect, useState } from 'react';
import './Admin.css';
import {S3Client} from "./clients/S3Client";
import {useNavigate} from "react-router-dom";
import SignInForm from "./signInForm";
import Alert from "./Alert";
import Header from "./Navbar";
import Footer from "./Footer";
import BrowseCataloge from "./browseCatalogue";
import {MdCloudUpload, MdImageSearch} from "react-icons/md";
import {encrypt} from "./clients/commonUtils";
import {CiShare2} from "react-icons/ci";
import {IoMdShare} from "react-icons/io";
import {FaUserGroup} from "react-icons/fa6";
import FileUploadComponent from './FileUploadComponent';
import Heading from "./Heading";

const SECRET = "9AvyW2EWBclwvGMcJmvim4zfBXLD6ngf";

const Admin = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [copied, setCopied] = useState(false);
    const [showUploadDialog, setShowUploadDialog] = useState(false);
    const [username, setUsername] = useState('');
    const [phone, setPhone] = useState('');
    const [errors, setErrors] = useState({});
    const [generatedLink, setGeneratedLink] = useState('');
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("isAdminAuthenticated") !== null);
    const [category, setCategory] = useState('');
    const [portfolioName, setPortfolioName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [showUploadMessage, setShowUploadMessage] = useState(false);
    const [validCredentials, setValidCredentials] = useState([]);
    const [uploading, setUploading] = useState(false);
    const formDetails = [
        {
            "title": "Username",
            "icon": "https://cdn.builder.io/api/v1/image/assets/TEMP/0f27e81e36f21d62bdd6573cda89ed5d1b9dd52ca6ee78c67fac60891a2b8118?apiKey=57fff759ecc84ffd8c4b9af5c4ff2ab5&",
            "placeholder": "Enter your username",
            "type": "text"
        },
        {
            "title": "Password",
            "icon": "https://cdn.builder.io/api/v1/image/assets/TEMP/2300d946ee2ad5aca266df004460804dd78e7ae1cbfd0769d6b99c41c450bad3?apiKey=57fff759ecc84ffd8c4b9af5c4ff2ab5&",
            "placeholder": "Enter your password",
            "type": "password"
        }
    ];

    const navigate = useNavigate();
    useEffect(() => {
        const handleDevToolsChange = () => {
            if (window.devtools.isOpen) {
                alert("hi")
                window.location.href = '/blank'
            } else {
                window.location.href = '/admin'
            }
        };

        // Attach the event listener
        window.addEventListener('devtoolschange', handleDevToolsChange);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('devtoolschange', handleDevToolsChange);
        };
    }, []);

    useEffect(async () => {
        setValidCredentials(await getUserData());
        console.log(validCredentials)
    }, []);

    function isNumeric(value) {
        return /^\d+$/.test(value);
    }

    const getUserData = async () => {
        try {
            const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
            const users = await uploader.fetchDataFromCSV('user.csv');
            users.map((user, index) => {
                users[index]['isAdmin'] = JSON.parse(user.isAdmin);
            });
            return users;
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleUploadPortfolioClick = () => {
        setShowUploadDialog(true);
    };

    const handleShareClick = () => {
        setShowDialog(true);
    };

    const handleCloseDialog = () => {
        setShowDialog(false);
        setUsername('');
        setPhone('');
        setErrors({});
        setGeneratedLink('');
    };

    const handleCloseUploadDialog = () => {
        setShowUploadDialog(false);
        setErrors({});
        setShowUploadMessage(false);
        // Reset form fields
        setCategory('');
        setPortfolioName('');
        setDescription('');
        setImages([]);
    };
    
    const uploadToS3 = async () => {
        try {
            setUploading(true); // Set uploading to true when upload starts
            const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
            const uploadedImageUrls = [];
            for (const image of images) {
                const imageUrl = await uploader.uploadImage(image);
                uploadedImageUrls.push(imageUrl);
            }
    
            for (const imgURL of uploadedImageUrls){
                const newRow = {category: category, portfolioName : portfolioName, description : description, imageUrl: imgURL, price: price}
                await uploader.appendRowToCsv('portfolio.csv', newRow)
            }
    
            setTimeout(() => {
                setShowUploadMessage(true);
            }, 100);
            setTimeout(() => {
                setShowUploadMessage(false);
                setUploading(false); // Clear uploading state after upload is completed
            }, 4000);
        } catch (error) {
            setErrors({ upload: 'Upload failed. Please try again.' });
            setUploading(false); // Clear uploading state if there's an error
        }
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log("hereeee")

        const errors = {};
        if (!username.trim()) {
            errors.username = 'Username is required';
        }
        else if (username.length > 50) {
            errors.username = 'Username should be less than 50 character';
        }
        if (!phone.trim()) {
            errors.phone = 'Phone number is required';
        }
        else if (!/^\d{10}$/.test(phone)) {
            if (!isNumeric(phone)) {
                errors.phone = 'Phone number must be positive numerics only';
            }
            else {
                errors.phone = 'Phone number must be 10 digits';
            }

        }
        else if (validCredentials.some(cred => cred.phone === phone)) {
            errors.phone = 'phone number already been registered';
        }

        if (Object.keys(errors).length === 0) {
            //need to remove this hard coding
            const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
            /// One more thing Yuvraj and Ravi we have to add post POC is to store that username and phone to csv
            generateUniqueLink().then(async uniqueLink => {
                console.log('Unique Link:', uniqueLink);
                const newRow = { username: username, password: 'None', phone: phone, isAdmin: 'false', uniqueLink: uniqueLink, device: 'null' };
                await uploader.appendRowToCsv('user.csv', newRow);
                await getUserData();
                setGeneratedLink(uniqueLink);
                // Decrypt the encrypted data and extract username and phone
            }).catch(error => {
                console.error('Error occurred:', error);
            });

        }

        setErrors(errors);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        const errors = {};
        if (!category) {
            const categoryLabel = document.querySelector('label[for="category"]').textContent.trim();
            errors.category = `${categoryLabel.slice(0, -1)} is required.`; // Remove the colon
        }
        if (!portfolioName.trim()) {
            const portfolioNameLabel = document.querySelector('label[for="portfolioName"]').textContent.trim();
            errors.portfolioName = `${portfolioNameLabel.slice(0, -1)} is required.`; // Remove the colon
        }
        else if (portfolioName.length > 30) {
            errors.portfolioName = ' Portfolio name should be less than or equal to 30 characters.';
        }
        if (!description.trim()) {
            const descriptionLabel = document.querySelector('label[for="description"]').textContent.trim();
            errors.description = `${descriptionLabel.slice(0, -1)} is required.`; // Remove the colon
        }
        else if (description.length > 250) {
            errors.description = ' Description should be less than or equal to 250 characters.';
        }

        if(!price) {
            errors.price = 'Price is required'
        }
        else if (price<=0){
            errors.price = 'Price should be greater than 0'
        }

        if (images.length===0){
            errors.image = 'Image should be uploaded'
        }
        if (!images) {
            const imageLabel = document.querySelector('label[for="image"]').textContent.trim();
            errors.image = `${imageLabel.slice(0, -1)} is required.`; // Remove the colon
        }
        if (Object.keys(errors).length === 0) {
            await uploadToS3();
            handleCloseUploadDialog();
        }
        setErrors(errors);
    };
    


    // Arrow function to encrypt data (username and phone) using a secret key

    const generateUniqueLink = async () => {
        const data = `${username}:${phone}`;
        try {
            // Encrypt the data using the secret key
            const encryptedData = encrypt(data, SECRET);
            // Construct the unique link using the encrypted data
            const uniqueLink = `https://shyamcreation.com/user/${encryptedData}`;
            return uniqueLink;
        } catch (error) {
            console.error('Error occurred during link generation:', error);
            return null;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('isAdminAuthenticated');
        setAuthenticated(false);
    };

    const handleCopyLink = async () => {
        await navigator.clipboard.writeText(generatedLink);
        // Show the copied label
        setCopied(true);
        // Hide the label after a short delay (e.g., 2 seconds)
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Adjust the delay as needed
    };

    const handleFileUploadSubmit = async (selectedFiles) => {
        const errors = {};
    
        // Check if category is selected
        if (!category) {
            errors.category = 'Category is required.';
        }
    
        // Check if portfolioName is filled and within limit
        if (!portfolioName.trim()) {
            errors.portfolioName = 'Portfolio name is required.';
        } else if (portfolioName.length > 30) {
            errors.portfolioName = 'Portfolio name should be less than or equal to 30 characters.';
        }
    
        // Check if description is filled and within limit
        if (!description.trim()) {
            errors.description = 'Description is required.';
        } else if (description.length > 250) {
            errors.description = 'Description should be less than or equal to 250 characters.';
        }
    
        // Check if price is filled and valid
        if (!price) {
            errors.price = 'Price is required.';
        } else if (price <= 0) {
            errors.price = 'Price should be greater than 0.';
        }
    
        // Check if images are uploaded
        if (selectedFiles.length === 0) {
            errors.image = 'Image should be uploaded.';
        }
    
        // If no errors, proceed with upload
        if (Object.keys(errors).length === 0) {
            await uploadToS3();
            handleCloseUploadDialog();
        }
    
        // Set errors state
        setErrors(errors);
        // Add your logic to handle the file upload submission here
        console.log('Selected Files:', selectedFiles);
    };
    

    const handleFileChange = (files) => {
        console.log(files);
        setImages(files)
    }

    const handleAdminLogin = (aUsername, pwd) => {
        console.log(aUsername)
        console.log(validCredentials)
        const err = {};
        const isValidUser = validCredentials.some(cred => cred.username === aUsername && cred.password === pwd);
        const user = validCredentials.find(cred => cred.username === aUsername && cred.password === pwd);
        if (isValidUser && user.isAdmin) {
            localStorage.setItem('isAdminAuthenticated', true);
            setAuthenticated(true);
        } else {
            localStorage.removeItem('isAdminAuthenticated');
            setAuthenticated(false);
            err.login = "Invalid username/ password or you don't have sufficient admin privilege";
        }
        setErrors(err);
    };
    const handleShowPortfolioClick = () => {
        window.location.href = '/admin/portfolio';
    };

    const handleImageUpload = (e) => {
        setImages(e.target.files)
        const selectedFiles = e.target.files;
        const supportedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    
        const invalidFiles = Array.from(selectedFiles).filter(file => !supportedTypes.includes(file.type));
    
        if (invalidFiles.length > 0) {
            setErrors({ image: 'Only PNG and JPEG files are supported.' });
            e.target.value = ''; // Clear the selected files
        } else {
            setImages(Array.from(selectedFiles));
            setErrors({}); // Clear any previous error
        }
    };

    return (
        <div className="admin-body">
            <Header isAuthenticated={authenticated} onLogOut={handleLogout}/>
        <div className="admin">
            {!authenticated ?(
                <div>
                    <SignInForm form={formDetails} onLogin={handleAdminLogin} singleFieldSignIn={false}/>
                    {errors.login?<Alert type="danger" text={errors.login} /> : <div></div> }
                    {/*{errors && <Alert variant="danger">{errors.login}</Alert>} */}
                </div>
                )
                :
                (<>
                    {showUploadMessage && (
                        <div className="upload-success-message">
                            Upload successful!
                            <span className="close-button" onClick={() => setShowUploadMessage(false)}>×</span>
                        </div>
                    )}
                    <Heading text="Shyam Creations" color="rgba(215, 4, 4, 1)"/>
                    <div className="browse-container">
                        <div className="browse-content">
                            <div className="browse-row">
                                <div>
                                    <BrowseCataloge text="Browse Catalog" icon={MdImageSearch} fontSize="20px"
                                                    onClickFunction={handleShowPortfolioClick}/>
                                </div>
                                <div style={{marginLeft: '20px'}}></div>
                                 <div>
                                                                    <BrowseCataloge text="Upload Portfolio" icon={MdCloudUpload} fontSize="20px"
                                                                                    onClickFunction={handleUploadPortfolioClick}/>
                                                                </div>

                            </div>
                            <div style={{marginTop: '20px'}}></div>
                            <div className="browse-row">
                               <div>
                                                                   <BrowseCataloge text="Customer Interests" icon={FaUserGroup} fontSize="20px"
                                                                                   onClickFunction={()=>{
                                                                                       window.location.href = '/interest'
                                                                                   }}/>
                                                               </div>
                                <div style={{marginLeft: '20px'}}></div>

                                <div>
                                                                    <BrowseCataloge text="Share" icon={IoMdShare} fontSize="20px"
                                                                                    onClickFunction={handleShareClick}/>
                                                                </div>
                            </div>
                        </div>
                    </div>
                    {showDialog && (
                        <div className="my-modal">
                            <div className="my-modal-content">
                                <span className="close" onClick={handleCloseDialog}>&times;</span>
                                {generatedLink ? (
                                    <div className="link-container">
                                        <p className="generated-link">{generatedLink}</p>
                                        <button className="copy-button" onClick={handleCopyLink}>Copy</button>
                                        {copied && <p className="copied-label">Copied!</p>}
                                    </div>
                                ) : (
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input type="text" id="username" name="username" placeholder="Enter customer name" 
                                                value={username}  onChange={(e) => setUsername(e.target.value)}  className="form-control"/>
                                            {errors.username && <span className="error">{errors.username}</span>}
                                        </div>
                        
                                        <div className="form-group">
                                            <input type="tel" id="phone" name="phone" placeholder="Enter customer mobile number" 
                                                value={phone}  onChange={(e) => setPhone(e.target.value)}  className="form-control"/>
                                            {errors.phone && <span className="error">{errors.phone}</span>}
                                        </div>

                                        <button type="submit" className="submit-button">Submit</button>
                                    </form>
                                )}
                            </div>
                        </div>
                    )}
                    {showUploadDialog && (
                         <div className="my-modal">
                            <div className="my-modal-content">
                                <span className="close" onClick={handleCloseUploadDialog}>&times;</span>
                                <div className="form-group">
                                    <select id="category" value={category} onChange={(e) => setCategory(e.target.value)} className="form-control">
                                        <option value="">Category</option>
                                        <option value="Simmer">Simmer</option>
                                        <option value="Dola">Dola</option>
                                        <option value="Orgenja">Orgenja</option>
                                        <option value="Modra">Modra</option>
                                        <option value="Chundri">Chundri</option>
                                        <option value="Bandej">Bandej</option>
                                        <option value="Lehriya">Lehriya</option>
                                        <option value="FancyShifon">Fancy Shifon</option>
                                        {/* Add More Categories here */}
                                    </select>
                                    {errors.category && <span className="error">{errors.category}</span>}
                                </div>
                        
                                <div className="form-group">
                                    <input type="text" id="portfolioName" placeholder="Enter item name" value={portfolioName} onChange={(e) => setPortfolioName(e.target.value)} className="form-control"/>
                                    {errors.portfolioName && <span className="error">{errors.portfolioName}</span>}
                                </div>
                        
                                <div className="form-group">
                                <input type="number" id="price" placeholder="Enter price" step="0.01" value={price} onChange={(e) => setPrice(parseFloat(e.target.value))} className="form-control"/>
                                {errors.price && <span className="error">{errors.price}</span>}
                                </div>
                                                            
                                <div className="form-group">
                                    <textarea id="description" placeholder="Enter description" value={description} onChange={(e) => setDescription(e.target.value)} className="form-control"/>
                                    {errors.description && <span className="error">{errors.description}</span>}
                                </div>
                        
                                <div className="form-group">
                                    <FileUploadComponent onFileUploadSubmit={handleFileUploadSubmit} onFileChange={handleFileChange}/>
                                    {errors.image && <span className="error">{errors.image}</span>}
                                </div>
                                {uploading && <div className="loader">Uploading...</div>}
                            </div>
                     </div>
                    )}
                </>)}

        </div>
            <div><Footer/></div>
        </div>
    );
};

export default Admin;