import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter,
    Route, Routes,
} from "react-router-dom";


import Admin from "./AdminPage";
import Home from "./Home";
import User from "./UserPage";
import AboutUs from "./AboutUs";
import Portfolio from "./Portfolio";
import UserDetails from "./UserDetails";
import SignInForm from "./signInForm";
import Carousel from "./Carousel";
import Header from "./Navbar";
import CollectionShowcaseMini from "./cardComponent";
import InfiniteCarousel from "./Carousel";
import Footer from "./Footer";
import BrowseCataloge from "./browseCatalogue";
import {MdImageSearch} from "react-icons/md";
import ModernImageCard from "./cardComponent";
import Interests from "./Interests";
import NotFoundPage from "./NothingToDisplay"; // Import the UserDetails component

var cards = [
    {"image":"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample87.jpg",
        "title":"Burgundy Flemming",
        "subtitle":"Advertising"},
    {"image":"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample119.jpg",
        "title":"Nigel Nigel",
        "subtitle":"Sound & Vision"},
    {"image":"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
        "title":"Caspian Bellevedere",
        "subtitle":"Accounting"}
];

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />} />
            {/*<Route path="/" element={ <BrowseCataloge text="Browse Catalog" icon={MdImageSearch} fontSize="20px" />} />*/}
            <Route path="/interest" element={<Interests />} />

            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/admin" element={<Admin />}>
            </Route>
            <Route path="/user" element={<User />}>
            </Route>
            <Route path="/user/:encryptedCode" element={<UserDetails />}></Route>
            <Route path="/admin/portfolio" element={<Portfolio />} />

        </Routes>
    </BrowserRouter>
    , document.getElementById('root'));
