import React, {useEffect, useState} from 'react';
import './Portfolio.css';
import {S3Client} from "./clients/S3Client";
import {useNavigate} from "react-router-dom";
import ModernImageCard from "./cardComponent";
import Header from "./Navbar";
import Heading from "./Heading";
import Footer from "./Footer";
import NotFoundPage from "./NothingToDisplay";

const Interests = () => {
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("isAdminAuthenticated") !== null);
    const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
    const [portfolios, setPortfolios] = useState([]);

    useEffect(() => {
        console.log(localStorage)
        if (!authenticated) {
            window.location.href = '/';
        } else {
            fetchPortfolioData();
        }
    }, [authenticated]);

    const fetchPortfolioData = async () => {
        try {
            const data = await uploader.fetchDataFromCSV('customerInt.csv');
            console.log(data)
            setPortfolios(data);
        } catch (error) {
            console.error('Error fetching customer Interests data:', error);
        }
    };

    const handleFullfilled = async (imageUrl) => {
        await uploader.deleteItemFromS3(imageUrl)
        await uploader.deleteRowFromCsv('customerInt.csv', 'imageUrl', imageUrl)
        await fetchPortfolioData();
        // Add your logic to remove the portfolio item
    };
    const handleLogout = () => {
        localStorage.removeItem('isAdminAuthenticated')
        setAuthenticated(false);
    };

    return (
        <div>
            <Header isAuthenticated={authenticated} onLogOut={handleLogout}/>
            <Heading text="Customer Interests" color="rgba(215, 4, 4, 1)"/>
            <div className="portfolio-list">
                {portfolios.length !== 0 ?portfolios.map((portfolio, index) => (
                    <div key={index}>
                        <><ModernImageCard src={uploader.generateSignedUrl(portfolio.imageUrl)} btnText="Fullfilled" btnClickFxn={handleFullfilled} name={portfolio.username} description={portfolio.phone} url={portfolio.imageUrl}/></>
                    </div>
                )): (<>
                    <NotFoundPage link='/admin'/>
                </>)}
            </div>
            <Footer />
        </div>
    );
};

export default Interests;
