import React from 'react';
import styled from 'styled-components';
import MapComponent from './MapComponent';

// Styled components for the form and map
const StyledSection = styled.section`
  padding: 50px 20px;
  background-color: #f8ede8;
`;

const StyledContainer = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

const StyledFormWrap = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const StyledFormContent = styled.div`
  flex: 0 0 55%; /* Form contents cover 55% of the width */
`;

const StyledMapWrap = styled.div`
  flex: 0 0 30%; /* Map covers 45% of the width */
  margin-top: 20px; /* Add some space between the form and the map */

  /* Hide the map on screens smaller than 768px (standard tablet portrait size) */
  @media screen and (max-width: 767px) {
    // display: none;
    flex: 0 0 100%;
  }
`;

const StyledTitle = styled.h1`
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const StyledParagraph = styled.p`
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 20px;

  a {
    color: #1e90ff;
    text-decoration: none;
  }
`;

const StyledInputGroup = styled.div`
  margin-bottom: 20px;

  input,
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }

  textarea {
    resize: vertical;
  }
`;

const StyledButton = styled.button`
  background-color: #1e90ff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  transition: background-color 0.3s;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #0066cc;
  }
`;

const ContactForm = () => {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const emailAddress = 'info@shyamcreation.com'; // Email address to pre-fill
    const subject = 'Inquiry'; // Subject of the email
    const body = 'Hello,\n\nI am interested in your services. Please get in touch with me.\n\nRegards,'; // Body of the email

    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    window.open(mailtoLink); // Open the user's email client with pre-filled information
  };

  return (
    <StyledSection className="contact-area" id="contact-area">
      <StyledContainer className="custom-container">
        <StyledFormWrap className="contact-form-wrap">
          <StyledFormContent>
            <StyledTitle className="section-title">Let's get in touch</StyledTitle>
            <StyledParagraph>
              You can reach us anytime via{' '}
              <a href="mailto:info@shyamcreation.com">info@shyamcreation.com</a>
            </StyledParagraph>
            <form className="contact-form" id="contact-form" onSubmit={handleSubmit}>
              <StyledInputGroup>
                <input type="text" placeholder="First Name" required />
              </StyledInputGroup>
              <StyledInputGroup>
                <input type="text" placeholder="Last Name" />
              </StyledInputGroup>
              <StyledInputGroup>
                <input type="email" placeholder="Your Email" required />
              </StyledInputGroup>
              <StyledInputGroup>
                <input type="text" placeholder="Your Number" required />
              </StyledInputGroup>
              <StyledInputGroup>
                <textarea placeholder="Leave us a message...." required></textarea>
              </StyledInputGroup>
              <StyledInputGroup>
                <StyledButton type="submit" id="submit" className="theme-btn">
                  Get Started
                </StyledButton>
              </StyledInputGroup>
            </form>
          </StyledFormContent>
          <StyledMapWrap>
            <MapComponent />
          </StyledMapWrap>
        </StyledFormWrap>
      </StyledContainer>
    </StyledSection>
  );
};

export default ContactForm;
