import React, {useState} from 'react';
import Header from "./Navbar";
import InfiniteCarousel from "./Carousel";
import Heading from "./Heading";
import ModernImageCard from "./cardComponent";
import ContactPage from './contactUsComponent';
import Footer from "./Footer";
import ImageGrid from "./ImageGrid";
import {S3Client} from "./clients/S3Client";

const Home = () => {
    const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("isAdminAuthenticated") !== null);
    var cards = [
        {"image":"https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image15.jpeg",
            "title":"Burgundy Flemming",
            "subtitle":"Advertising"},
        {"image":"https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image16.jpeg",
            "title":"Nigel Nigel",
            "subtitle":"Sound & Vision"},
        {"image":"https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image17.jpeg",
            "title":"Caspian Bellevedere",
            "subtitle":"Accounting"}
    ];
    var portfolios = [
        {
            imageUrl : 'https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image7.jpeg',
            portfolioName : "Simmer Collection",
            description: "SS SARJOKI"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image8.jpeg",
            portfolioName : "Simmer Collection",
            description: "SANTA SQ HEAVYHAND WORK"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image9.jpeg",
            portfolioName : "ORGENJA",
            description: "EMBROIDERY SQ"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image10.jpeg",
            portfolioName : "ORGENJA",
            description: "MIRROR JAAL"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image11.jpeg",
            portfolioName : "Fancy Shifon Collection",
            description: "KHADI. EMBROIDERY SQ"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image12.jpeg",
            portfolioName : "Fancy Shifon Collection",
            description: "BRUSH PRINT WORK"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image13.jpeg",
            portfolioName : "DOLA Collection",
            description: "MEENA BANDEJ"
        },
        {
            imageUrl : "https://shyam-creations.s3.ap-south-1.amazonaws.com/static_images/image14.jpeg",
            portfolioName : "DOLA Collection",
            description: "2D MEENA BANDEJ"
        }
    ]
    const handleLogout = () => {
        localStorage.removeItem('isAdminAuthenticated');
        setAuthenticated(false);
    };

    const header = (
        <>
          <h2>Contact Us</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        </>
      );
    
    const contactInfo = {
        address: '123 Main Street, Anytown, USA, 12345',
        phone: '571-457-2321',
        email: 'contact@example.com'
    };

    return (
        <div>
            <Header isAuthenticated={authenticated} onLogOut={handleLogout}/>
            <InfiniteCarousel images={cards}/>
            <div>
                <Heading text="EXCLUSIVE COLLECTION" color="rgba(215, 4, 4, 1)"/>
                <ImageGrid />
            </div>
            <Heading text="OUR BEST SELLERS" color="rgba(215, 4, 4, 1)"/>
            <div className="portfolio-list">
                {portfolios.map((portfolio, index) => (
                    <div key={index}>
                        <ModernImageCard src={uploader.generateSignedUrl(portfolio.imageUrl)} name={portfolio.portfolioName}
                                         description={portfolio.description}/>
                    </div>
                ))
                }
            </div>
            <ContactPage header={header} contactInfo={contactInfo} />
            <div><Footer/></div>
        </div>
    )
};

export default Home;
