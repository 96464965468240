import React, {useEffect, useState} from 'react';
import './User.css';
import {S3Client} from "./clients/S3Client";
import Fingerprint2 from 'fingerprintjs2';
import { useParams } from 'react-router-dom';
import {decrypt} from "./clients/commonUtils";
const SECRET = "9AvyW2EWBclwvGMcJmvim4zfBXLD6ngf";


const UserDetails = () => {
    const { encryptedCode } = useParams();
    const [phoneNo, setphoneNo] = useState('');
    const [errors, setErrors] = useState({});
    const [validCredentials, setValidCredentials] = useState([])
    const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');

    // Decrypt the encrypted code to retrieve the username
    console.log({encryptedCode})
    const decryptedData = decrypt(encryptedCode).split(':');; // Implement decrypt function
    const [decryptedUsername, decryptedPassword] = decryptedData;

    useEffect(async () => {
        setValidCredentials(await getUserData());
    }, []);

    const getUserData = async() =>{
        try {
            const users = await uploader.fetchDataFromCSV('user.csv');
            users.map((user, index) => {
                users[index]['isAdmin'] = JSON.parse(user.isAdmin)
            })
            return users
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const generateFingerprint = async () => {
        try {
            const components = await Fingerprint2.getPromise();
            const values = components.map(component => component.value);
            return Fingerprint2.x64hash128(values.join(''), 31) // Update state with the fingerprint value
        } catch (error) {
            console.error('Error generating fingerprint:', error);
        }
    };

    const handleLogin = async () => {
        const err = {};
        const isValidUser = decryptedPassword === phoneNo
        const user = validCredentials.find(cred => cred.phone === phoneNo);
        if (isValidUser) {
            if (user.device === "null") {
                const newRow = {username: user.username, password : 'None', phone : user.phone, isAdmin : 'false', uniqueLink: user.uniqueLink, device:  await generateFingerprint()}
                await uploader.deleteRowFromCsv('user.csv', 'phone', user.phone)
                await uploader.appendRowToCsv('user.csv', newRow);
                window.location.href = '/user';
                setErrors({});
            }
            else{
                window.location.href = '/user';
            }
        } else {
            err.login = "Phone Number Did'nt match. ";
            setErrors(err);
        }
    };


    return (
        <div>
        {(
            <div className="user-container">
                <h2>Welcome, {decryptedUsername}</h2>
                <input type="tel" id="phone" name="phone" placeholder="phone number" value={phoneNo}
                       onChange={(e) => setphoneNo(e.target.value)} />
                {errors.login && <span className="error">{errors.login}</span>}
                <button onClick={handleLogin}>Register</button>
            </div>
        )}
    </div>
    );
};

export default UserDetails;