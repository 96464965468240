import React, {useEffect, useState} from 'react';
import './Portfolio.css';
import {S3Client} from "./clients/S3Client";
import {useNavigate} from "react-router-dom";
import ModernImageCard from "./cardComponent";
import Header from "./Navbar";
import Heading from "./Heading";
import Footer from "./Footer";
import {decrypt} from "./clients/commonUtils";
import NotFoundPage from "./NothingToDisplay";

const Portfolio = () => {
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("isAdminAuthenticated") !== null);
    const [userAuthenticated, setUserAuthenticated] = useState(localStorage.getItem("isUserAuthenticated") !== null);
    const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
    const [portfolios, setPortfolios] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [showInterestMessage, setShowInterestMessage] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        console.log(localStorage)
        if (!authenticated && !userAuthenticated) {
            window.location.href = '/';
        } else {
            fetchPortfolioData();
        }
        // Disable right click
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        // Prevent Ctrl+P from triggering print
        const handleKeyDown = (e) => {
            if ((e.ctrlKey || e.metaKey) && e.key === 'p') {
              e.preventDefault(); 
            }
        };
        // Prevent the default print behavior
        const handleBeforePrint = (e) => {
            e.preventDefault(); 
        };

        window.addEventListener('keydown', handleKeyDown); // Add event listener for keydown
        window.addEventListener('beforeprint', handleBeforePrint); // Add event listener before printing
      
        return function cleanup() {
            window.removeEventListener('contextmenu', handleContextmenu)
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('beforeprint', handleBeforePrint); // Remove event listener when component unmounts
        } 
    }, [authenticated, userAuthenticated]);

    const fetchPortfolioData = async () => {
        try {
            const data = await uploader.fetchDataFromCSV('portfolio.csv');
            console.log(data)
            setPortfolios(data);
        } catch (error) {
            console.error('Error fetching portfolio data:', error);
        }
    };

    const handleRemove = async (imageUrl) => {
        await uploader.deleteItemFromS3(imageUrl)
        await uploader.deleteRowFromCsv('portfolio.csv', 'imageUrl', imageUrl)
        await fetchPortfolioData();
        // Add your logic to remove the portfolio item
    };
    const handleLogout = () => {
        localStorage.removeItem('isAdminAuthenticated')
        localStorage.removeItem('isUserAuthenticated')
        setAuthenticated(false);
        setUserAuthenticated(false)
    };

    const handleInterested = async (imageUrl) => {
        window.open('https://wa.me/9829619799', "_blank", "noreferrer");
        const decryptedData = decrypt(localStorage.getItem('loggedUserDetails')).split(':'); // Implement decrypt function
        const [decryptedUsername, decryptedPhone] = decryptedData;
        console.log(decryptedPhone)
        console.log(decryptedUsername)
        const newRow = {username: decryptedUsername,phone : decryptedPhone,imageUrl:imageUrl}
        await uploader.appendRowToCsv('customerInt.csv', newRow);
        setTimeout(() => {
            setShowInterestMessage(true);
        }, 100);
        setTimeout(() => {
            setShowInterestMessage(false);
        }, 4000);
        
    };

    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const filteredPortfolios = portfolios.filter(portfolio => 
        selectedCategory === 'All' || portfolio.category === selectedCategory
    );
    

    return (
        <div>
            <Header isAuthenticated={authenticated || userAuthenticated} onLogOut={handleLogout}/>
            <Heading text="Current Portfolio" color="rgba(215, 4, 4, 1)"/>
            <div className="category-filter">
                <label htmlFor="category">Filter by Category:</label>
                <select id="category" value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="All">All</option>
                    {/* Add options dynamically based on unique categories */}
                    {Array.from(new Set(portfolios.map(p => p.category))).map((category, index) => (
                        <option key={index} value={category}>{category}</option>
                    ))}
                </select>
            </div>
            {showInterestMessage && (
                <div className="upload-success-message">
                    Interests Sent!
                    <span className="close-button" onClick={() => setShowInterestMessage(false)}>×</span>
                </div>
            )}
            <div className="portfolio-list">
                {filteredPortfolios.length !== 0 ?filteredPortfolios.map((portfolio, index) => (
                    <div key={index}>
                            {authenticated?(<><ModernImageCard src={uploader.generateSignedUrl(portfolio.imageUrl)} btnText="Remove" btnClickFxn={handleRemove} name={`Category : ${portfolio.category} `} description={`${portfolio.portfolioName} - ${portfolio.description}`} url={portfolio.imageUrl} price={portfolio.price}/></>):(<><ModernImageCard src={uploader.generateSignedUrl(portfolio.imageUrl)} btnText="Interested" btnClickFxn={handleInterested} name={portfolio.portfolioName} description={portfolio.description} url={portfolio.imageUrl} price={portfolio.price}/></>)}
                    </div>
                )): (<>
                {authenticated?(<><NotFoundPage link='/admin'/></>):(<><NotFoundPage link='/'/></>)}
                </>)}
            </div>
            <Footer />
        </div>
    );
};

export default Portfolio;
