import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for animation
const zoomIn = keyframes`
  from {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
`;

const zoomOut = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`;

// Styled components
const CardContainer = styled.div`
  max-width: 300px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  position: relative;
  &:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
    margin: 18px;
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  animation: ${zoomIn} 0.3s ease forwards;
  ${ImageContainer}:hover & {
    animation: ${zoomOut} 0.3s ease forwards;
  }
`;

const InterestedButton = styled.button`
  position: absolute;
  bottom: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 123, 255, 0.9);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  transform: translateY(100%);
  ${CardContainer}:hover & {
    transform: translateY(0);
  }
`;

const PricePill = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border-radius: 20px;
  font-size: 14px;
`;

const Content = styled.div`
  padding: 20px;
  background-color: #fff;
`;

const Name = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
`;


// Component
const ModernImageCard = ({src, btnText='None', btnClickFxn, price='None', name, description ,url}) => {
    const [showButton, setShowButton] = useState(false);

    return (
        <CardContainer>
            <ImageContainer
                onMouseEnter={() => setShowButton(true)}
                onMouseLeave={() => setShowButton(false)}
            >
                <Image src={src} alt="Image" />
                {btnText !== 'None' && showButton && <InterestedButton onClick={()=>{btnClickFxn(url)}}>{btnText}</InterestedButton>}
                {price!== 'None' && <PricePill>Rs. {price}</PricePill>}
            </ImageContainer>
            <Content>
                <Name>{name}</Name>
                <Description>{description}</Description>
                
            </Content>
        </CardContainer>
    );
};

export default ModernImageCard;
