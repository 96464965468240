import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 80%;
  padding: 50px 0;
  margin: 50px auto;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px;
    width: 2px;
    height: 100%;
    background: #CCD1D9;
    z-index: 1;
  }
`;


const TimelineBlock = styled.div`
  width: calc(50% + 8px);
  display: flex;
  justify-content: space-between;
  clear: both;

  &.timeline-block-right {
    float: right;
  }

  &.timeline-block-left {
    float: left;
    direction: rtl;
  }
`;

const Marker = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  background: #4FC1E9;
  margin-top: 10px;
  z-index: 9999;
`;

const Content = styled.div`
  width: 95%;
  padding: 0 15px;
  color: #666;

  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500;
  }

  span {
    font-size: 15px;
    color: #a4a4a4;
  }

  p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
    color: #888;
  }
`;

const Timeline = ({ events }) => {
  return (
    <div>
      <Container>
        {events.map((event, index) => (
          <TimelineBlock key={index} className={`timeline-block ${index % 2 === 0 ? 'timeline-block-right' : 'timeline-block-left'}`}>
            <Marker />
            <Content>
              <h3>{event.title}</h3>
              <span>{event.subtitle}</span>
              <p>{event.description}</p>
            </Content>
          </TimelineBlock>
        ))}
      </Container>
    </div>
  );
};

export default Timeline;
