import * as React from "react";
import styled from "styled-components";
import { useState } from "react";

function Header({ isAuthenticated , onLogOut}) {
    console.log(isAuthenticated)
    const [showMenu, setShowMenu] = useState(false);

    return (
        <HeaderWrapper>
            <HeaderContent>
                <HeaderRow>
                    <SiteLinks>
                        <Logo src="/logo.svg" alt="Company Logo" onClick={()=>{window.location.href='/'}} />
                        {localStorage.getItem("isAdminAuthenticated") ? (
                        <NavLinks>
                            <NavLink href="/admin/portfolio">Shyam Exclusive Collection</NavLink>
                            <NavLink href="/admin">Admin Console</NavLink>
                            <NavLink href="/interest">Customer Interests</NavLink>
                        </NavLinks>
                        ) : (
                          <NavLinks>
                            <NavLink href="/user">Shyam Exclusive Collection</NavLink>
                            <NavLink href="/AboutUs">About Us</NavLink>
                            <NavLink href="/#contact-area">Contact Us</NavLink>
                          </NavLinks>
                        )
                      } 
                    </SiteLinks>
                    <HeaderActions>
                        <RequestDemoButton onClick={()=>{
                          if (localStorage.getItem("isAdminAuthenticated") !== null) {
                            window.location.href="tel:8302309973"
                          }
                          else {
                            window.location.href="tel:9829619799"
                          }
                          }}> 
                              {localStorage.getItem("isAdminAuthenticated")? "Contact Apara" : "Call Us"}
                          </RequestDemoButton>
                        <Separator />
                        {!isAuthenticated? <LoginButtons>
                            {/* <LoginAsAdminButton onClick={()=>{window.location.href='/admin'}}>Login As Admin</LoginAsAdminButton> */}
                            <LoginAsUserButton onClick={()=>{window.location.href='/user'}}>Login As User</LoginAsUserButton>
                        </LoginButtons>: <LoginButtons>
                            <LoginAsAdminButton onClick={()=>{onLogOut()}}>Logout</LoginAsAdminButton>
                        </LoginButtons>}
                    </HeaderActions>
                    <MenuToggle
                        src="https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2F0663bd40b99a4259a807f6f0014df73d"
                        alt="Menu Toggle"
                        onClick={() => setShowMenu(!showMenu)}
                    />
                </HeaderRow>
            </HeaderContent>
            {showMenu && (
                <MobileMenu>
                        {localStorage.getItem("isAdminAuthenticated") ? (
                            <>
                                <AccordionItem>
                                    <AccordionTitle onClick={() => {window.location.href='/admin/portfolio'}}>Shyam Exclusive Collection</AccordionTitle>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionTitle onClick={() => {window.location.href='/admin'}}>Admin Console</AccordionTitle>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionTitle onClick={() => {window.location.href='/interest'}}>Customer Interests</AccordionTitle>
                                </AccordionItem>
                            </>
                        ) : (
                            <>
                                <AccordionItem>
                                    <AccordionTitle onClick={() => {window.location.href='/user'}}>Shyam Exclusive Collection</AccordionTitle>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionTitle onClick={() => {window.location.href='/AboutUs'}}>About Us</AccordionTitle>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionTitle onClick={() => {window.location.href='/#contact-area'}}>Contact Us</AccordionTitle>
                                </AccordionItem>
                            </>
                        )}
                        
                        <MobileActions>
     

                        <MobileRequestDemoButton onClick={()=>{

                          if (localStorage.getItem("isAdminAuthenticated") !== null) {
                            window.location.href="tel:8302309973"
                          }
                          else {
                            window.location.href="tel:9829619799"
                          }
                        }}>
                            {localStorage.getItem("isAdminAuthenticated")? "Contact Apara" : "Contact Us"}
                        </MobileRequestDemoButton>
                        {!isAuthenticated? <>
                        <MobileLoginButton onClick={()=>{window.location.href='/user'}}>Login as User</MobileLoginButton></>:
                        <><MobileLoginButton onClick={()=>{onLogOut()}}>Logout</MobileLoginButton></>
                        }
                    </MobileActions>
                </MobileMenu>
            )}
        </HeaderWrapper>
    );
}

const HeaderWrapper = styled.header`
    margin-top: 20px;
    background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 1px 2px 8px 1px rgba(155, 155, 155, 1);
  padding: 8px 20px 8px 0;
  border-radius: 12px; /* Problem 1: Rounded navbar box */
    overflow-y:hidden;
    white-space: nowrap;
`;

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-self: stretch;
  margin: 0 auto;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 12px 0;

  @media (max-width: 991px) {
    justify-content: flex-start;
  }

  @media (max-width: 640px) {
    align-items: center;
  }
`;

const SiteLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-right: auto;
  }

  @media (max-width: 640px) {
    justify-content: flex-start;
  }
`;

const Logo = styled.img`
  max-height: 48px;
  max-width: 89px;
  object-fit: contain;
  padding-left: 10px;  
  cursor: pointer;

  @media (max-width: 991px) {
    margin-right: auto;
  }

  @media (max-width: 640px) {
    max-height: 42px;
    max-width: 53px;
    min-width: 42px;
    margin: auto 0;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 17px;
  margin-top: 15px;
  font-family: "Montserrat", sans-serif; /* Problem 2: Font family */
  @media (max-width: 991px) {
    display: none;
  }
  text-decoration: none;
`;

const NavLink = styled.a`
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  &:hover {
    color: rgba(215, 4, 4, 1); /* Problem 4: Change color on hover */
  }
  text-decoration: none;
  color:black;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 991px) {
    display: none;
  }
`;

const RequestDemoButton = styled.button`
  font-family: "Montserrat", sans-serif;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 0;  
`;

const Separator = styled.div`
  width: 2px;
  height: 32px;
  background-color: rgba(207, 207, 207, 1);
`;

const LoginButtons = styled.div`
  display: flex;
  gap: 16px;
`;

const LoginButton = styled.button`
  font-family: "Montserrat", sans-serif;
  padding: 12px;
  border-radius: 20px;
  background-color: rgba(215, 4, 4, 1);
  color: white;
  cursor: pointer;
  margin-top: 0;  
  transition: all 0.3s ease; /* Problem 5: Animation on hover */
  &:hover {
    transform: translateX(5px); /* Problem 5: Animation on hover */
  }
`;

const LoginAsAdminButton = styled(LoginButton)`
  @media (max-width: 991px) {
    display: flex;
  }

  @media (max-width: 640px) {
    display: none;
  }
`;

const LoginAsUserButton = styled(LoginButton)``;

const MenuToggle = styled.img`
  display: none;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media (max-width: 991px) {
    display: flex;
    max-width: 48px;
    align-self: flex-start; /* Align toggle icon with the logo */
    margin-left: auto; /* Move toggle icon to the right */
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: auto;
    color: rgba(215, 4, 4, 1);
  }
`;

const MobileMenu = styled.div`
  display: none;
  margin-top: 20px;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
      
  }
`;

const AccordionItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccordionTitle = styled.div`
  padding: 10px;
  background-color: rgba(234, 234, 234, 1);
  font-family: "Montserrat", sans-serif;
  cursor: pointer;

  @media (max-width: 991px) {
    background-color: rgba(255, 255, 255, 1);
  }
`;

const AccordionDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const MobileNavLink = styled.a`
  font-family: "Montserrat", sans-serif;
  padding: 8px 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(207, 207, 207, 1); /* Add separator */
  &:hover {
    color: rgba(215, 4, 4, 1); /* Problem 4: Change color on hover */
  }
`;

const MobileActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 10px;
`;

const MobileRequestDemoButton = styled.button`
  font-family: "Montserrat", sans-serif;
  padding: 12px;
  border-radius: 4px;
  cursor: pointer;
`;

const MobileLoginButton = styled.button`
  font-family: "Montserrat", sans-serif;
  padding: 12px;
  border-radius: 20px;
  background-color: rgba(215, 4, 4, 1);
  color: white;
  cursor: pointer;
`;

export default Header;
