import React, {useEffect, useState} from 'react';
import './User.css';
import {S3Client} from "./clients/S3Client";
import Fingerprint2 from 'fingerprintjs2';
import Portfolio from "./Portfolio";
import {encrypt} from "./clients/commonUtils";
import SignInForm from "./signInForm";
import Alert from "./Alert";
import Header from "./Navbar";
import Footer from "./Footer";

const User = () => {
    const SECRET = "9AvyW2EWBclwvGMcJmvim4zfBXLD6ngf";
    const [errors, setErrors] = useState({});
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("isUserAuthenticated") !== null)
    const [validCredentials, setValidCredentials] = useState([])
    const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');
    const formDetails = [
        {
            "title": "Phone no. ",
            "icon": "https://cdn.builder.io/api/v1/image/assets/TEMP/0f27e81e36f21d62bdd6573cda89ed5d1b9dd52ca6ee78c67fac60891a2b8118?apiKey=57fff759ecc84ffd8c4b9af5c4ff2ab5&",
            "placeholder": "Enter your phone number",
            "type": "tel"
        },
    ];

    useEffect(async () => {
        setValidCredentials(await getUserData());
    }, []);

    const getUserData = async() =>{
        try {
            const users = await uploader.fetchDataFromCSV('user.csv');
            users.map((user, index) => {
                users[index]['isAdmin'] = JSON.parse(user.isAdmin)
            })
            return users
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('isUserAuthenticated');
        setAuthenticated(false);
    };

    const generateFingerprint = async () => {
        try {
            const components = await Fingerprint2.getPromise();
            const values = components.map(component => component.value);
            return Fingerprint2.x64hash128(values.join(''), 31) // Update state with the fingerprint value
        } catch (error) {
            console.error('Error generating fingerprint:', error);
        }
    };

    const handleLogin = async (phoneNo) => {
        console.log(validCredentials)
        const err = {};
        // Simulated authentication logic
        const isValidUser = validCredentials.some(cred => cred.phone === phoneNo);
        const user = validCredentials.find(cred => cred.phone === phoneNo);
        if (isValidUser) {
            if (user.device === "null") {
                localStorage.setItem('isUserAuthenticated', true);
                const newRow = {username: user.username, password : 'None', phone : user.phone, isAdmin : 'false', uniqueLink: user.uniqueLink, device:  await generateFingerprint()}
                await uploader.deleteRowFromCsv('user.csv', 'phone', user.phone)
                await uploader.appendRowToCsv('user.csv', newRow);
                const data = `${user.username}:${user.phone}`;
                try {
                    const encryptedData = encrypt(data, SECRET);
                    localStorage.setItem("loggedUserDetails",encryptedData)
                } catch (error) {
                    console.error('Error occurred during link generation:', error);
                    return null;
                }
                setValidCredentials(await getUserData())
                setAuthenticated(true);

                setErrors({});
            }
            else if(await generateFingerprint() === user.device){
                localStorage.setItem('isUserAuthenticated', true);
                const data = `${user.username}:${user.phone}`;
                try {
                    const encryptedData = encrypt(data, SECRET);
                    localStorage.setItem("loggedUserDetails",encryptedData)
                } catch (error) {
                    console.error('Error occurred during link generation:', error);
                    return null;
                }
                setAuthenticated(true);
                setErrors({});
            }
            else{
                localStorage.removeItem('isUserAuthenticated');
                setAuthenticated(false);
                err.login = "Invalid device login";
                setErrors(err)
            }
        } else {
            localStorage.removeItem('isUserAuthenticated');
            setAuthenticated(false);
            err.login = "Invalid phone number";
            setErrors(err);
        }
    };


    return (
        <div>
            {!authenticated ? (

                    <div>
                        <Header isAuthenticated={authenticated} onLogOut={handleLogout}/>
                        <SignInForm form={formDetails} onLogin={handleLogin} singleFieldSignIn={true}/>
                        {errors.login?<Alert type="danger" text={errors.login} /> : <div></div> }
                        {/*{errors && <Alert variant="danger">{errors.login}</Alert>} */}
                        <div><Footer/></div>
                    </div>
            ) : (
                <div>
                    <Portfolio/>
                </div>
            )}
        </div>
    );
};

export default User;
