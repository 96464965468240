import React, {useEffect, useState} from "react";
import styled from "styled-components";



const InputField = ({ icon, placeholder, value, onChangeFxn, type }) => (
    <InputWrapper>
        <InputIcon src={icon} alt="" />
        <Input type={type} placeholder={placeholder} value={value} onChange={onChangeFxn}/>

    </InputWrapper>
);


const SignInForm = ({form, onLogin , singleFieldSignIn}) => {
    const [adminUsername, setAdminUsername] = useState('');
    const [password, setPassword] = useState('');
    const updateUserName = (e) =>{
        setAdminUsername(e.target.value)
    }
    const updatePassword = (e) =>{
        setPassword(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onLogin(adminUsername, password);
    };

    return (
        !singleFieldSignIn?(<><Container onSubmit={handleSubmit}>
            <FormTitle>{form[0].title}</FormTitle>
            <InputField
                icon={form[0].icon}
                placeholder={form[0].placeholder}
                value={adminUsername}
                onChangeFxn={updateUserName}
                type={form[0].type}
            />
            <FormTitle>{form[1].title}</FormTitle>
                <InputField
                    icon={form[1].icon}
                    placeholder={form[1].placeholder}
                    value={password}
                    onChangeFxn={updatePassword}
                    type={form[1].type}
                />
            <SubmitButton type="submit">Sign in</SubmitButton>
        </Container></>):(<><Container onSubmit={handleSubmit}>
                <FormTitle>{form[0].title}</FormTitle>
                <InputField
                    icon={form[0].icon}
                    placeholder={form[0].placeholder}
                    value={adminUsername}
                    onChangeFxn={updateUserName}
                    type={form[0].type}
                />
                <SubmitButton type="submit">Sign in</SubmitButton>
            </Container></>)
    );
};

const Container = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 50%;
  padding: 0 20px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
  position: relative;
  top: 50%;
  left: 35%;
  margin-top: 15%;
  margin-bottom: 10%;  
    @media (max-width: 991px) {
        margin-top: 35%;
        position: relative;
        top: 0;
        left: 0;
        margin-left: 35%;
        transform: none;
        max-width: 100%;
        padding: 0 10px;
        
    }
    @media (max-width: 450px) {
        margin-left: 0;
        margin-top: 50%;
        position: relative;
        left: 0;
        top: 0;
        transform: none;
        max-width: 100%;
        padding: 0 10px;
    }
`;

const FormTitle = styled.label`
  font-family: Poppins, sans-serif;
  width: 100%;
  margin-top: 12px;
  font-size: 15px;
    padding-left: 15px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px 25px; /* Added padding here */
  margin-top: 5px;
  border-radius: 50px;
  background-color: rgba(248, 247, 251, 1);
  color: var(--Light-Mode-Grey-500, #7f909f);
  font-weight: 400;
  letter-spacing: 0.18px;
  line-height: 171%;
  width: 35%;
    @media (max-width: 1050px) {
        width: 40%;
    }
    @media (max-width: 991px) {
        width: 50%;
    }
    @media (max-width: 720px) {
        width: 65%;
    }
    @media (max-width: 450px) {
        width: 75%;
    }
`;

const InputIcon = styled.img`
  width: 20px;
  height: auto;
  object-fit: contain;
  filter: invert(28%) sepia(91%) saturate(2993%) hue-rotate(341deg) brightness(81%) contrast(92%);
`;

const Input = styled.input`
  font-family: Poppins, sans-serif;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
  min-width: 0; /* Ensure minimum width */
    color: #000000;
  width: 100%; /* Take up available width */
  &::placeholder {
    color: var(--Light-Mode-Grey-500, #7f909f);
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const PasswordInputWrapper = styled(InputWrapper)`
  justify-content: space-between;
`;

const TogglePasswordVisibilityIcon = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
  cursor: pointer;
  filter: invert(28%) sepia(91%) saturate(2993%) hue-rotate(341deg) brightness(81%) contrast(92%);
`;

const SubmitButton = styled.button`
  margin-top: 50px;
  border-radius: 50.35px;
  background-color: #d70404;
  color: #fff;
  font: 600 20px/150% Poppins, sans-serif;
  text-align: center;
  letter-spacing: 0.4px;
  cursor: pointer;
  border: none;
  width: 15%;
    @media (max-width: 1050px) {
        width: 20%;
    }
    @media (max-width: 991px) {
        width: 25%;
    }
    @media (max-width: 720px) {
        width: 32%;
    }
  @media (max-width: 450px) {
    width: 40%;
  }
`;

export default SignInForm;
