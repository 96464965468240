import React, {useState} from 'react';
import styled from 'styled-components';
import Header from "./Navbar";
import Footer from "./Footer";
import Timeline from './timelineComponent';
import Heading from "./Heading";

const P = styled.p`
    font-size: 24px; /* Increased font size */
    margin-top: 20px;
    color: #000000;
    text-align: center; /* Center the text */
    padding: 0 20%; /* Add padding on the sides, adjusted for centering */

    @media (max-width:640px) {
        font-size: 16px; /* Adjusted font size for smaller screens */
    }
`;

const events = [
    {
      title: '2008: Humble Beginnings in Katla',
      subtitle: 'Founded',
      description: 'In 2008, Shyam Creation started with a small 50-square-foot shop in Kotla. With just 2-3 saree designs, we began our journey with a deep passion for textiles and traditional craftsmanship. This modest start laid the groundwork for our future growth and dedication to quality.',
      image: 'url_to_image_here'
    },
    {
      title: 'Early Years',
      subtitle: 'Expanded Design',
      description: ' Shyam Creation experienced significant growth. We expanded our operations, moving to a larger space to accommodate an increasing variety of saree designs. This period marked a pivotal moment in our journey, as we introduced new collections and styles that captivated our customers, establishing us as a notable name in ethnic fashion.',
      image: 'url_to_image_here'
    },
    {
        title: 'Present: Expanding Horizons',
        subtitle: 'Diverse Collections Under One Roof',
        description: 'Today, Shyam Creation operates from a four-story building in Ram Lallaji Ka Rasta, Johri Bajar. We proudly offer a wide range of categories, reflecting our growth and commitment to diversity in ethnic fashion.',
        image: 'url_to_image_here'
      },
    // Add more events as needed
  ];

const AboutUs = () => {
    const [authenticated, setAuthenticated] = useState(localStorage.getItem("isUserAuthenticated") !== null);
    var cards = [
        {"image":"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample87.jpg",
            "title":"Burgundy Flemming",
            "subtitle":"Advertising"},
        {"image":"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample119.jpg",
            "title":"Nigel Nigel",
            "subtitle":"Sound & Vision"},
        {"image":"https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            "title":"Caspian Bellevedere",
            "subtitle":"Accounting"}
    ];
    var portfolios = [
        {
            imageUrl : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            portfolioName : "Sample 1",
            description: "this is sample description"
        },
        {
            imageUrl : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            portfolioName : "Sample 1",
            description: "this is sample description"
        },
        {
            imageUrl : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            portfolioName : "Sample 1",
            description: "this is sample description"
        },
        {
            imageUrl : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            portfolioName : "Sample 1",
            description: "this is sample description"
        },
        {
            imageUrl : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            portfolioName : "Sample 1",
            description: "this is sample description"
        },
        {
            imageUrl : "https://s3-us-west-2.amazonaws.com/s.cdpn.io/331810/sample120.jpg",
            portfolioName : "Sample 1",
            description: "this is sample description"
        }
    ]
    const handleLogout = () => {
        localStorage.removeItem('isUserAuthenticated');
        setAuthenticated(false);
    };
   
    const imgStyle = {
        display: 'block',
        margin: '0 auto',
        maxWidth: '85%',
        height: 'auto',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' /* Add box shadow */
    };
  
    return (
        <div>
            <Header isAuthenticated={authenticated} onLogOut={handleLogout}/>
            
            <br/>
            <Heading text="About Shyam Creations" color="rgba(215, 4, 4, 1)"/>
            <img src='/ShyamCreationsAbout.jpeg' alt='About Shyam Creations' style={imgStyle} />
            <P>
                    At Shyam Creation, we are more than just a saree manufacture, we are purveyors of tradition, custodians of craftsmanship, and champions of elegance. Founded in 2008, our journey began with a passion for textiles and a vision to redefine ethnic fashion. Today, we stand as a beacon of quality and innovation in the world of sarees, driven by our unwavering commitment to excellence.
            </P>
            <div>
                <Timeline events={events} />
            </div>
            <Heading text="Why to choose us?" color="rgba(215, 4, 4, 1)"/>
            <P>
            1. <b>Quality Craftsmanship:</b> Each saree at Shyam Creation is crafted with precision and care, using only the finest materials and techniques to ensure superior quality and durability.
            <br/>  <br/>
            2. <b>Innovative Designs:</b> Our team of talented designers constantly pushes the boundaries of creativity, bringing fresh perspectives and innovative designs to life that captivate and inspire.
            <br/>  <br/>
            3. <b>Customer Satisfaction:</b> We prioritize the needs and preferences of our customers, striving to exceed their expectations with exceptional products and personalized service that fosters long-lasting relationships.

            </P>
           
            <div><Footer/></div>
        </div>
    )
};

export default AboutUs;
