import React from 'react';
import styled from 'styled-components';

// Define alert colors
const alertColors = {
    success: '#4CAF50',
    danger: '#F44336',
    warning: '#FFC107',
};

// Styled component for the alert container
const AlertContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 9999;
`;

// Styled component for the alert box
const AlertBox = styled.div`
  background-color: ${props => alertColors[props.type] || '#2196F3'};
  color: #fff;
  padding: 15px 20px;
  border-radius: 4px;
  margin: 20px;
    
`;

// Alert component
const Alert = ({ type, text }) => {
    return (
        <AlertContainer>
            <AlertBox type={type}>
                {text}
            </AlertBox>
        </AlertContainer>
    );
};

export default Alert;
