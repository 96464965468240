import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {S3Client} from "./clients/S3Client";
const uploader = new S3Client('shyam-creations', 'AKIAYS2NRJR4FZZK6SE4', 't0cDxAikt4+h1DGvsVlRF+paBp/bcOuQnI3vJD8A', 'ap-south-1');

const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center; /* Center the carousel horizontally */
`;

const SlideContainer = styled.div`
    margin-top: 75px;
  display: flex;
  justify-content: space-between;
  transition: transform 0.5s ease;
  width: calc(100% - 20px); /* Adjusted to prevent overflow */
  @media (max-width: 768px) {
    width: calc(100% - 10px); /* Adjusted width for tablet devices */
  }
`;

const Slide = styled.div`
  max-width: 33.333333%;
  position: relative;
    //overflow: ${({ isActive }) => (!isActive ? 'visible' : 'hidden')};
  //overflow: visible; /* Clip the image */
`;

const Image = styled.img`
  width: ${({ isActive }) => (isActive ? '160%' : '150%')}; /* Increase width of center image */
  height: ${({ isActive }) => (isActive ? '60%' : '50%')}; /* Adjusted height for mobile */
    margin-left: ${({isThird})=> isThird ? '40%':undefined};
    margin-left: ${({isSecond})=> isSecond ? '100%':undefined};
    margin-left: ${({isActive})=> isActive ? '-175%':undefined};
    @media (max-width: 1624px) {
        margin-left: ${({isThird})=> isThird ? '30%':undefined};
        margin-left: ${({isSecond})=> isSecond ? '90%':undefined};
        margin-left: ${({isActive})=> isActive ? '-150%':undefined};
    }
    @media (max-width: 1468px) {
        margin-left: ${({isThird})=> isThird ? '10%':undefined};
        margin-left: ${({isSecond})=> isSecond ? '70%':undefined};
        margin-left: ${({isActive})=> isActive ? '-140%':undefined};
    }
    @media (max-width: 1268px) {
        margin-left: ${({isThird})=> isThird ? '0':undefined};
        margin-left: ${({isSecond})=> isSecond ? '50%':undefined};
        margin-left: ${({isActive})=> isActive ? '-140%':undefined};
    }
    @media (max-width: 620px) {
        margin-left: ${({isThird})=> isThird ? '0':undefined};
        margin-left: ${({isSecond})=> isSecond ? '50%':undefined};
        margin-left: ${({isActive})=> isActive ? '-140%':undefined};
         /* Adjusted margin for tablet devices */
    }
  display: block;
  border-radius: 20px; /* Add rounded corners to images */
    border-radius: ${({ isActive }) => (isActive ? '40px' : '20px')};
  box-shadow: ${({ isActive }) => (isActive ? '0 0 10px rgba(0, 0, 0, 0.5)' : 'none')}; /* Add box shadow to central image */
  margin-top: ${({ isActive }) => (isActive ? '20px' : '48px')}; /* Adjusted margin for center image */
    @media (max-width: 1268px) {
        margin-top: ${({ isActive }) => (isActive ? '25px' : '60px')};
        height: ${({ isActive }) => (isActive ? '85%' : '65%')};
        width: ${({ isActive }) => (isActive ? '190%' : '150%')};
        /* Adjusted margin for tablet devices */
    }
    @media (max-width: 620px) {
    margin-top: ${({ isActive }) => (isActive ? '10px' : '30px')};
        height: ${({ isActive }) => (isActive ? '85%' : '65%')};
        width: ${({ isActive }) => (isActive ? '190%' : '150%')}; /* Increase width of center image */
        /* Adjusted margin for tablet devices */
  }
    
  transition: width 0.5s ease, margin 0.5s ease, box-shadow 0.5s ease; /* Smooth transition for width, margin, and box shadow */
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
`;

const PrevButton = styled(ArrowButton)`
  left: 10px;
`;

const NextButton = styled(ArrowButton)`
  right: 10px;
`;

const Article = ({ data, isActive , isSecond, isThird}) => {
    const { image, title, subtitle } = data;

    return (
        <Slide>
            <Image src={uploader.generateSignedUrl(image)} alt={title} isActive={isActive} isSecond={isSecond} isThird={isThird}/>
        </Slide>
    );
};

const InfiniteCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(1); // Start with the third image as active

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <CarouselContainer>
            <PrevButton onClick={prevSlide}>{'<'}</PrevButton>
            <SlideContainer>
                {[currentIndex - 1, currentIndex + 1, currentIndex].map((index) => {
                    const adjustedIndex = (index + images.length) % images.length;
                    return (
                        <Article
                            key={adjustedIndex}
                            data={images[adjustedIndex]}
                            isActive={index === currentIndex}
                            isSecond={index === currentIndex + 1}
                            isThird={index === currentIndex - 1}
                        />
                    );
                })}
            </SlideContainer>
            <NextButton onClick={nextSlide}>{'>'}</NextButton>
        </CarouselContainer>
    );
};

export default InfiniteCarousel;
