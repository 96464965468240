import React, { useRef, useEffect, useState } from 'react';

const Heading = ({ text, color }) => {
    const textRef = useRef(null);
    const [textWidth, setTextWidth] = useState(0);

    useEffect(() => {
        if (textRef.current) {
            setTextWidth(textRef.current.offsetWidth);
        }
    }, [text]);

    return (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <div style={{ display: 'inline-block' }}>
                <h2 ref={textRef} style={{ color, margin: '0' }}>{text}</h2>
            </div>
            <br/>
        </div>
    );
};

export default Heading;
