import { csvParse, csvFormat } from 'd3-dsv';
const AWS = require('aws-sdk');

export class S3Client {
    constructor(bucketName, accessKeyId, secretAccessKey, region) {
        this.s3 = new AWS.S3({
            accessKeyId,
            secretAccessKey,
            region
        });
        this.bucketName = bucketName;
    }

    async uploadImage(imageFile) {
        const params = {
            Bucket: this.bucketName,
            Key: generateUniqueFilename(imageFile.name),
            Body: imageFile,
            ContentType: imageFile.type
        };

        try {
            const uploadResult = await this.s3.upload(params).promise();
            return uploadResult.Location;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }
    }

    generateSignedUrl(s3Url) {
        const parsedUrl = new URL(s3Url);
        const objectKey = parsedUrl.pathname.substring(1);

        const params = {
            Bucket: this.bucketName,
            Key: objectKey,
        };

        try {
            return this.s3.getSignedUrl('getObject', params);
        } catch (error) {
            console.error('Error generating signed URL:', error);
            throw error;
        }
    }

    async deleteItemFromS3(urlToDelete) {
        const parsedUrl = new URL(urlToDelete);

        const objectKey = parsedUrl.pathname.substring(1);

        const params = {
            Bucket: this.bucketName,
            Key: objectKey
        };

        try {
            // Delete the object from the bucket
            await this.s3.deleteObject(params).promise();
            console.log('Item deleted successfully');
        } catch (error) {
            console.error('Error deleting item:', error);
            throw error;
        }
    }

    appendRowToCsv = async (key, newRow) => {
        try {
            const params = {
                Bucket: this.bucketName,
                Key: key
            };
            const response = await this.s3.getObject(params).promise();
            let csvData = response.Body.toString('utf-8');
            const lines = csvData.trim().split('\n');
            const lastLineIsEmpty = lines[lines.length - 1].trim() === '';
            if (lastLineIsEmpty) {
                lines[lines.length - 1] = Object.values(newRow).join(',');
            } else {
                lines.push(Object.values(newRow).join(','));
            }
            csvData = lines.join('\n');
            const uploadParams = {
                Bucket: this.bucketName,
                Key: key,
                Body: csvData,
                ContentType: 'text/csv'
            };
            await this.s3.upload(uploadParams).promise();
            console.log('New row appended successfully.');
        } catch (error) {
            console.error('Error appending row to CSV:', error);
            throw error;
        }
    };



    deleteRowFromCsv = async (key, columnName, columnValue) => {
        try {
            const params = {
                Bucket: this.bucketName,
                Key: key
            };
            const response = await this.s3.getObject(params).promise();
            let csvData = response.Body.toString('utf-8');
            let rows = csvData.split('\n');
            const headerRow = rows.shift();
            const headers = headerRow.split(',');
            const columnIndex = headers.findIndex(header => header.trim() === columnName.trim());

            // Filter out rows with the specified column value
            rows = rows.filter(row => {
                // Check if the row is empty
                if (row.trim() === '') {
                    return false; // Skip empty row
                }
                const columns = row.split(',');
                return columns[columnIndex].trim() !== columnValue.trim();
            });

            // Rebuild CSV data
            csvData = headers.join(',') + '\n' + rows.join('\n');

            const uploadParams = {
                Bucket: this.bucketName,
                Key: key,
                Body: csvData,
                ContentType: 'text/csv'
            };
            await this.s3.upload(uploadParams).promise();

            console.log('Row(s) deleted successfully.');
        } catch (error) {
            console.error('Error deleting row from CSV:', error);
            throw error;
        }
    };




    async fetchDataFromCSV(csvFileName) {
        const params = {
            Bucket: this.bucketName,
            Key: csvFileName
        };

        try {
            const data = await this.s3.getObject(params).promise();
            const csvData = data.Body.toString(); // Assuming CSV data is in string format
            const rows = csvData.split('\n').map(row => row.trim());
            const headers = rows[0].split(',').map(header => header.trim()); // Trim headers
            const portfolios = [];
            for (let i = 1; i < rows.length; i++) {
                const rowData = rows[i].split(',').map(cell => cell.trim()); // Trim cells
                if (rowData.length !== headers.length) {
                    console.warn(`Row ${i + 1} has inconsistent number of columns.`);
                    continue;
                }
                const portfolio = {};
                for (let j = 0; j < headers.length; j++) {
                    portfolio[headers[j]] = rowData[j];
                }
                portfolios.push(portfolio);
            }
            return portfolios;
        } catch (error) {
            console.error('Error fetching portfolio data from CSV:', error);
            throw error;
        }
    }



}


function generateUniqueFilename(originalFilename) {
    const extension = originalFilename.split('.').pop();
    const timestamp = Date.now();
    return `${timestamp}-${Math.random().toString(36).substring(2, 15)}.${extension}`;
}

