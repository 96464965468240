import React, { memo } from 'react';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  position: relative;
  width: 35vw; /* Default width for mobile */
  height: 35vw; /* Height equal to width */
  border-radius: 15px;
  background-color: #bc7f4e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 400px) {
    width: 25vw;
    height: 25vw; /* Width for screens wider than 400px */
  }

  @media (min-width: 800px) {
    width: 20vw;
    height: 20vw; /* Width for screens wider than 800px */
  }

  @media (min-width: 1200px) {
    width: 12vw;
    height: 12vw; /* Width for screens wider than 1200px */
  }
`;

const BrowseCatalogText = styled.div`
  color: #fff;
  font-size: 4vw; /* Responsive font size based on viewport width */
  font-weight: 600;
  text-align: center;
  margin-top: 10px; /* Add margin to the top */

  @media (min-width: 400px) {
    font-size: 3vw; /* Adjust font size for screens wider than 400px */
  }

  @media (min-width: 800px) {
    font-size: 2.5vw; /* Adjust font size for screens wider than 800px */
  }

  @media (min-width: 1200px) {
    font-size: 1.25vw; /* Adjust font size for screens wider than 1200px */
  }
`;

const IconWrapper = styled.div`
  font-size: 8vw; /* Responsive font size based on viewport width */
  color: #fff; /* Adjust color as needed */

  @media (min-width: 400px) {
    font-size: 6vw; /* Adjust font size for screens wider than 400px */
  }

  @media (min-width: 800px) {
    font-size: 4vw; /* Adjust font size for screens wider than 800px */
  }

  @media (min-width: 1200px) {
    font-size: 3vw; /* Adjust font size for screens wider than 1200px */
  }
`;

// React Component
const BrowseCataloge = memo(({ text, icon: IconComponent , onClickFunction}) => {
    return (
        <Container onClick={()=>{onClickFunction()}}>
            <IconWrapper><IconComponent /></IconWrapper>
            <BrowseCatalogText>{text}</BrowseCatalogText>
        </Container>
    );
});

export default BrowseCataloge;
