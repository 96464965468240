import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FaPhone } from 'react-icons/fa6';

const FooterContainer = styled.footer`
    font-family: "Roboto", Sans-serif;
    color: #292929;
    position: relative;
    bottom: 0;
    vertical-align: bottom;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 5%; /* Push footer to the bottom of the viewport */
    @media (max-width: 991px) {
        position: relative;
        margin-top: 60%;
        padding: 0;
    }
    @media (max-width: 850px) {
        position: relative;
        margin-top: 55%;
        padding: 0;
    }
    @media (max-width: 810px) {
        position: relative;
        margin-top: 35%;
        padding: 0;
    }
    @media (max-width: 450px) {
        margin-top: 40%;
        padding: 0;
        position: relative;
    }
    @media (max-width: 380px) {
        margin-top: 15%;
        padding: 0;
        position: relative;
    }
`;

const FooterContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }
`;

const LogoSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 768px) {
        align-items: flex-start;
    }
`;

const CompanyLogo = styled.img`
    width: 100px;
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        width: auto;
        margin-bottom: 0;
    }
`;

const Tagline = styled.p`
    margin: 0;
    text-align: center;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
`;

const FooterLinks = styled.div`
    display: none; /* Hide footer links by default in mobile view */

    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }
`;

const FooterLink = styled.a`
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5em;
    letter-spacing: 0.2px;
    color: #999;
    text-decoration: none;
    transition: color 0.3s ease;
    margin-bottom: 10px;

    &:hover {
        color: rgba(215, 4, 4, 1);
    }

    @media screen and (min-width: 768px) {
        margin-left: 20px;
        margin-bottom: 0;
    }
`;

const Separator = styled.hr`
    width: 100%;
    border: 0;
    border-top: 1px solid rgba(215, 4, 4, 1);
    margin: 20px 0;
`;

const ThirdRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const Copyright = styled.p`
    margin: 0;
`;

const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;

    @media screen and (min-width: 768px) {
        margin-top: 0;
    }
`;

const SocialIcon = styled.div`
    font-size: 24px;
    color: rgba(215, 4, 4, 1);
    cursor: pointer;
    margin-right: 20px;
`;

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <LogoSection>
                    <CompanyLogo src="/logo.svg" alt="Company Logo" />
                    {/* <Tagline>Company Tagline</Tagline> */}
                </LogoSection>
                <FooterLinks>
                    <FooterLink href="#"> <FontAwesomeIcon icon={faPhone} /> : 9829619799</FooterLink>
                    <FooterLink href="#"><FontAwesomeIcon icon={faEnvelope} /> : info@shyamcreation.com</FooterLink>
                </FooterLinks>
            </FooterContent>
            <Separator />
            <ThirdRow>
            <Copyright>
                &copy; 2024 Your Company. All rights reserved. Designed & Developed by <a style={{textDecoration: "none"}} href='https://aparaedge.com/'> AparaEdge </a> 
            </Copyright>

                <SocialIcons>
                    <SocialIcon><FontAwesomeIcon icon={faFacebookF} /></SocialIcon>
                    <SocialIcon><FontAwesomeIcon icon={faTwitter} /></SocialIcon>
                    <SocialIcon><FontAwesomeIcon icon={faInstagram} /></SocialIcon>
                </SocialIcons>
            </ThirdRow>
        </FooterContainer>
    );
};

export default Footer;
