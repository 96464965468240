import React from 'react';
import styled from 'styled-components';

const MapContainer = styled.div`
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  height: 400px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

const MapComponent = () => {
  return (
    <MapContainer>
      <iframe
        title="Map"
        width="100%"
        border-radius="10px"
        height="100%"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.542241764167!2d75.82474239999999!3d26.9180192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db7000f989e93%3A0x99773ee981c6c89!2sShyam%20Creation!5e0!3m2!1sen!2sin!4v1720701196185!5m2!1sen!2sin`}
      />
    </MapContainer>
  );
};

export default MapComponent;
