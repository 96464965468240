import React from 'react';
import styled, { keyframes } from 'styled-components';
import './NothingToDisplay.css';

const NotFoundPage = ({link}) => {
    return (
        <div id="404-body">

            <main>
                <section>
                    <h1>Nothing to Display</h1>
                    <div>

                    </div>
                    <p>There is Nothing on the page to be displayed that <br/>you'r]e looking for.</p>
                    <div>
                        <button onClick={()=> window.location.href=link}>Back to Home Page</button>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default NotFoundPage;
